<template>
  <div
    v-editable="blok"
    class="ArticleListing"
    :class="desktopWidth('full')"
  >
    <div v-if="blok.headline || richtext" class="layout-container">
      <h1 v-if="blok.headline" class="type-base-medium desk:type-headline-xl mb-8 text-center">
        {{ blok.headline }}
      </h1>
      <div
        v-if="richtext !== '<p></p>'"
        class="type-xs desk:type-base mb-24 text-center"
        v-html="richtext"
      />
    </div>
    <SideScroll v-if="blok.showKeywords" class="desk:layout-container mb-24 desk:mb-32">
      <div class="flex justify-start desk:justify-center gap-8 mobOnly:ml-12">
        <button
          class="btn btn--tab btn--tab--sm"
          :class="{
            'selected': filterKeyword === '',
          }"
          @click="setFilterKeyword('')"
        >
          {{ $t('articles.latestBtn') }}
        </button>
        <button
          v-for="keyword in keywords"
          :key="keyword"
          class="btn btn--tab btn--tab--sm"
          :class="{
            'selected': keyword === filterKeyword,
          }"
          @click="setFilterKeyword(keyword)"
        >
          {{ $t(`articles.keyword.${keyword}`) }}
        </button>
        <div class="w-16 desk:hidden">&nbsp;</div>
      </div>
    </SideScroll>
    <div v-if="blok.showKeywords" class="layout-container mb-16">
      <div class="text-darkest type-headline-sm">
        {{
          availableArticlesNum > 1 ? $t('articles.total.plur', {num: availableArticlesNum}) : availableArticlesNum === 1 ? $t('articles.total.sing') : $t('articles.total.none')
        }}
      </div>
    </div>
    <div v-if="blok.design === 'sideswipe'" class="desk:layout-container relative">
      <div
        v-if="joinedStories.length > 3"
        class="hidden desk:flex justify-end"
      >
        <div
          class="swiper-button-prev !top-[138px]"
          :class="[`swiper-for-blok-${blok._uid}`]"
        />
        <div
          class="swiper-button-next !top-[138px]"
          :class="[`swiper-for-blok-${blok._uid}`]"
        />
      </div>
      <client-only>
        <Swiper
          class="swiper-cover desk:!overflow-hidden !overflow-visible w-full desk:!pb-40 !pb-32"
          :space-between="isMobile ? 4 : 12"
          :slides-per-view="uiStore.isMobile ? 2.1 : 3"
          :modules="[SwiperNavigation, SwiperPagination, SwiperA11y]"
          :pagination="{
            clickable: true,
            dynamicBullets: true,
          }"
          :navigation="{
            nextEl: `.swiper-button-next.swiper-for-blok-${blok._uid}`,
            prevEl: `.swiper-button-prev.swiper-for-blok-${blok._uid}`,
          }"
        >
          <SwiperSlide
            v-for="story in joinedStories"
            :key="story.uuid"
            class="type-headline-sm desk:type-headline-lg"
          >
            <ArticleListingCard
              :story="story"
            />
          </SwiperSlide>
        </Swiper>
      </client-only>
    </div>
    <div v-else class="grid grid-cols-6 gap-x-8 gap-y-32 desk:gap-x-12 desk:gap-y-48">
      <ArticleListingCard
        v-for="(story, index) in joinedStories"
        :key="story.uuid"
        :story="story"
        :class="{
          'col-span-6 desk:col-span-3 type-headline-base desk:type-headline-xl': index % 8 < 2,
          'col-span-3 desk:col-span-2 type-headline-sm desk:type-headline-lg': index % 8 >= 2 && index % 8 < 8
        }"
      />
    </div>

    <!-- load more -->
    <LoadProgress
      v-if="!loading && blok.showLoadMore && blok.limit !== '0'"
      button-text="articles.loadmore"
      :current="currentShowingNum"
      :total="availableArticlesNum"
      text="articles.viewed"
      class="mt-48"
      @load-more="loadMoreStories"
    />
  </div>
</template>

<script setup lang="ts">
import { useGlobalContentStore } from '~/store/globalContent';
import { LinkOnly, StoryBlokPropBase, StoryBlokArticlePage } from '~/constants/types/storyblok';
import ArticleListingCard from '~/storyblok/articles/ArticleListingCard.vue';

import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import useDesktopWidth from '~/composeables/useDesktopWidth';
import LoadProgress from '~/components/generic/LoadProgress.vue';
import { ISbRichtext, ISbStoriesParams } from 'storyblok-js-client';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import useStoryblokAdmin from '~/composeables/useStoryblokAdmin';
const { isInStoryblokAdmin } = useStoryblokAdmin();

const storyblokApi = await useStoryblokApi();
const uiStore = useUiStore();
const globalContent = useGlobalContentStore();
const { isMobile } = storeToRefs(uiStore);
const { replaceLinksInStoryBlokResponse } = useLinkReplacer();

type Props = StoryBlokPropBase & {
  headline: string,
  text: ISbRichtext,
  limit: string,
  showLoadMore: boolean,
  manualArticles: LinkOnly[],
  design: '' | 'sideswipe',
  showKeywords: boolean,
  imageAspectRatio: string,
}

const props = defineProps<{
  blok: Props,
}>();
const { desktopWidth } = useDesktopWidth(props);
const { $toNumber } = useNuxtApp();

const richtext = computed(() => renderRichText(props.blok.text));
const folder = globalContent.articlesFolder;
const loading = ref(false);

const excludedIds = [ globalContent.pageId ]; // excluding self
/* Getting manually selected articles */
const manualIds = props.blok.manualArticles.map((i) => {
  if (i.link.story?.id) {
    excludedIds.push(i.link.story?.id);
  }
  return i.link.story?.uuid;
});

const totalAutoStories = ref(0);
const manualStories = ref([]);
const autoStories = ref([]);
const autoLoadedPage = ref(1);
const perPage = ref($toNumber(props.blok.limit));

// * * * * * watch inside storyblok
if (isInStoryblokAdmin()) {
  watch(() => props.blok.limit, (newLimit) => {
    perPage.value = $toNumber(newLimit);
    loadAutoStories();
  });
}

// * * * * * start loading thing
const storyblokPayloadBase = {
  version: useRuntimeConfig().public.storyblokStoriesVersion,
  starts_with: (!isInStoryblokAdmin() && !globalContent.isSubFolder ? globalContent.getStoryblokBasePath :  '') +  folder,
  resolve_links: 'url',
  content_type: 'ArticlePage',
  excluding_ids: excludedIds.join(','),
  //page: autoLoadedPage.value,
  sort_by: 'sort_by_date:desc',
} as ISbStoriesParams;

const { data } = await useAsyncData('loadArticlePage', async() => {
  // Loading manual stories
  let loadedManual = [];
  if (manualIds.length) {
    const { data } = await storyblokApi.get('cdn/stories/', {
      version: useRuntimeConfig().public.storyblokStoriesVersion as 'draft' | 'published',
      by_uuids: manualIds.join(','),
    });
    if (data?.stories) {
      loadedManual = data.stories;
    }
  }
  const remaining = parseInt(props.blok.limit) - loadedManual.length;
  let loadedAuto = [];
  let serverStoryCount = -1;
  if (remaining > 0) {
    perPage.value = remaining;
    const { data: storyblokData, headers } = await storyblokApi.get('cdn/stories/', {
      ...storyblokPayloadBase,
      per_page: remaining,
    });
    if (storyblokData) {
      loadedAuto = storyblokData.stories;
      serverStoryCount = parseInt(headers.total ?? data.stories.length, 10);
    }
  }

  return {
    loadedManual,
    loadedAuto,
    serverStoryCount,
  };
});

if (data.value) {
  manualStories.value = data.value.loadedManual;
  autoStories.value = data.value.loadedAuto;
  totalAutoStories.value = data.value.serverStoryCount;
}

const loadMoreStories = () => {
  loadAutoStories(true);
};
/**
 * Reloads auto stories, either to change keyword, or load more
 */
const loadAutoStories = async(append = false) => {
  if (append) {
    autoLoadedPage.value = autoLoadedPage.value + 1;
  } else {
    autoLoadedPage.value = 1;
  }

  const payload = {
    ...storyblokPayloadBase,
    page: autoLoadedPage.value,
    per_page: perPage.value,
  };
  if (filterKeyword.value !== '') {
    payload.filter_query = {
      summaryCategories: {
        like: filterKeyword.value,
      },
    };
  }

  const { data, headers } = await storyblokApi.get('cdn/stories/', payload);

  if (append) {
    autoStories.value = autoStories.value.concat(data.stories);
  } else {
    autoStories.value = data.stories;
    totalAutoStories.value = parseInt(headers.total ?? data.stories.length, 10);
  }
};

const joinedStories = computed<StoryBlokArticlePage[]>(()=>{
  return replaceLinksInStoryBlokResponse(manualStories.value.concat(autoStories.value));
});

const availableArticlesNum = computed<number>(() => {
  return totalAutoStories.value + (manualIds.length ?? 0);
});
const currentShowingNum = computed<number>(()=> {
  return joinedStories.value.length;
});

// * * * * * Keywords
const keywords = await globalContent.getArticleKeywords();
const filterKeyword = ref('');
const setFilterKeyword = (keyword: string) => {
  filterKeyword.value = keyword;
  loadAutoStories();
};

onActivated(() => {
  setFilterKeyword('');
});

const jsonLd = {
  '@context': 'https://schema.org/',
  '@type': 'ItemList',
  numberOfItems: joinedStories.value.length,
  itemListElement: joinedStories.value.map((story) => {
    const article = story.content;
    const articleObject = {
      '@context': 'https://schema.org',
      '@type': 'NewsArticle',
      headline: article.summaryTitle,
    } as any;

    if (article.summaryImage?.filename) {
      articleObject.image = [
        article.summaryImage.filename,
      ];
    }
    return articleObject;
  }),
} as any;

useJsonld(jsonLd);

</script>

<style scoped lang="postcss">

.slideWidth {
  width: calc(100vw - 48px);
}
.swiper-button-next {
  @apply !-right-16;
}
.swiper-button-prev {
  @apply !-left-16;
}

</style>
